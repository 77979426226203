import React, { Component } from 'react';
import PropTypes, { any } from 'prop-types'; //prop-types
import { withStyles } from '@mui/styles';
import { Typography, Grid } from '@mui/material/';
import rfiFormInitialValues from './layout/FormModels/RFIFormInitialValues'
import MaterialRadio from "./layout/MaterialComponents/MaterialRadio";
import { Formik, Form } from "formik";
import styles from './layout/styles/MobileFormStyles';
import UOPHeader from './layout/UopHeader';
import Footer from './layout/Footer';
import SelectField from './UopxNativeSelectField';
import MaterialInput from './layout/MaterialComponents/MaterialInput';
import MaterialPhoneNumber from './layout/MaterialComponents/MaterialPhoneNumber';
import { validateOneStep } from './layout/MobileFormValidation';
import { addDropDownOption, processJSONParameter } from './common/configTools';
import getIPAddress, { unescapeHTML, loadCookiesData, roleChange, submitForm, conditionalCBE, pageNameByPath, fireAnalyticsEvent, fireFOS_DlEvent, getDegreeLevel, updatePrePopCookieValue } from './CommonComponents';
import { courseCategoryDropdown, degreeLevel, degreeLevelVariant, dynamicFieldOfStudyDropdownVariant, radioOption, higherEducation } from './common/constants';
import FormikErrorFocus from 'formik-error-focus';
//import ErrorFocus from './common/ErrorFocus';
import Button from './layout/MaterialComponents/button/Button';
import ZipCode from './layout/MaterialComponents/ZipCode';
import FormikCheckbox from './layout/MaterialComponents/checkbox/FormikCheckbox';
import SingleCheckbox from './layout/MaterialComponents/checkbox/SingleCheckbox';
import {dataLayer} from './common/dataLayer';
import skillsblade from './layout/images/skillsblade.svg';

const formRef = {};
var feedback = {};
let prevFOS, prevDegreeLevel, prevState, prevCourseCategory, prevHighestEduLevel;
let courseCategoryFlag = false;
const disclaimerText = rfiFormInitialValues.disclaimer;
let isSavingsExplorer = false;
let subHeader = "Answer a few questions and an enrollment representative will connect with you to chat about options that can help you save time and money on your degree.";
const conditionalRadio = (value) => {
    let valid = false;
    switch (value) {
        case "healthcare":
            valid = true;
            break;
        case "nursing":
            valid = true;
            break;
        default:
    }
    return valid;
};

class DynamicDesktopRFI extends Component {
    constructor(props) {
        super(props);
        this.initialState = {
            isSubmit: false,
            initialValues: rfiFormInitialValues,
            formRef: null,
            variant: props.variant,
            feedback: any,
	        activeStep: 1,
            fieldOfStudyOptions: processJSONParameter([...dynamicFieldOfStudyDropdownVariant]),
            degreeLevel: processJSONParameter([...degreeLevelVariant]),
            initialDegreeLevel: processJSONParameter([...degreeLevelVariant]),
            courseCateogry: processJSONParameter(props.courseCategoryDropdown || [...courseCategoryDropdown]),
        };
        this.state = this.initialState;
    }

    componentDidMount() {
        window.pagePath = "page-dd_rfi";
        document.title = `Request Information about Programs and Courses at the University of Phoenix. - Desktop`;
        formPageType = "RFI";
        formType = "RFI";
        formName = "RFI dynamic desktop - 1 step";
        let { initialValues } = this.state;
        initialValues =  loadCookiesData(initialValues);
        //let userInfo = userAuthInfo();
        if (document.referrer.indexOf('/cost-savings/ways-to-save/results')>0){
            isSavingsExplorer=true;
            subHeader="Answer a few questions and an enrollment representative will reach out soon.";
        }
        console.log(this.state.fieldOfStudyOptions);
        prevFOS = initialValues.fieldOfStudy ? initialValues.fieldOfStudy : undefined;
        prevDegreeLevel = initialValues.degreeLevel ? initialValues.degreeLevel : undefined;
        prevHighestEduLevel = initialValues.highestLevelOfEducation ? initialValues.highestLevelOfEducation : undefined;
        this.setState({ initialValues });
        if(prevFOS){
            this.resetDegreeLevel(prevFOS)
        }
        getIPAddress(this.updateIPAddress);
        this.handleZipcodeChange(initialValues.postalCode, initialValues.stateProvince);
        const { activeStep } = this.state;
        formStep = activeStep;

        // fetch feedback
    /*    let baseUrl = process.env.REACT_APP_PHXEDU_BASE_URL;
        // console.log("Current env is: " + process.env.NODE_ENV);
        if (window.location.host !== 'www.phoenix.edu') {
            baseUrl = process.env.REACT_APP_PHXEDU_STAGE_BASE_URL;
        }

        fetch(baseUrl + '/services/feedback/v1/summary',
            {
                method: 'GET',
            })
            .then(response => response.json())
            .then(data => feedback = data)
            .catch(function (error) {
                console.log(error);
            }); */
	    if (this.state.RFIStep1 !== true) {
            setTimeout(function(){ fireAnalyticsEvent('RFIStep1', formName, formType); }, 2000);
            this.setState({ RFIStep1: true });
        }
        dataLayer();
    }

    updateIPAddress = (ipAddress) => {
        const { initialValues } = this.state;
        initialValues.ipAddress = ipAddress;
        this.setState({ initialValues });
    }

    handleChange = (e, props) => {
        const { name, value } = e.target;
        if (name === 'isMilitary') {
            formRef.current.values.isMilitary = value;
        }
        if (name === 'isRegisteredNurse') {
            formRef.current.values.isRegisteredNurse = value;
            this.setState({degreeLevel:getDegreeLevel(this.state.initialDegreeLevel,this.state.initialValues.fieldOfStudy, value)});
        }
    };

    dropdownChange = (name, value) => {
        const {initialValues} = this.state;
        if (name === 'fieldOfStudy') {
            if (value && prevFOS !== value) {
                updatePrePopCookieValue('fieldOfStudy', value);
                fireFOS_DlEvent(name, value, formName, formType);
                if(value !== "healthcare" && value !== "nursing" && formRef.current.values.isRegisteredNurse !== "" ) formRef.current.setFieldValue("isRegisteredNurse", "");        
                if( value !== "business" && value !== "technology" && value !== "healthcare" && formRef.current.values.isCBE !== "" ) formRef.current.setFieldValue("isCBE", "");
                prevFOS = value;
                initialValues.fieldOfStudy = value;
                if(initialValues.fieldOfStudy === "nondegree") {
                    courseCategoryFlag = true;
                }else {
                    courseCategoryFlag = false;
                    this.resetDegreeLevel(value);
                }
                
            }
        }
        else if (name === 'degreeLevel') {
            if (value && prevDegreeLevel !== value) {
                updatePrePopCookieValue('degreeLevel', value);
                fireFOS_DlEvent(name, value, formName, formType);
                if (value !== "masters" && formRef.current.values.isCBE !== "") formRef.current.setFieldValue("isCBE", "");
                prevDegreeLevel = value;
                initialValues.degreeLevel = value
                validateOneStep(initialValues);
            }
        }
        if (name === 'flex_Course_Category__c') {
            if ((value && prevCourseCategory !== value) || value === '') {
                fireFOS_DlEvent('courseCategory', value, formName, formType);
                prevCourseCategory = value;                
            }
        }
        if (name === 'highestLevelOfEducation') {
            if (value && prevHighestEduLevel !== value) {
                updatePrePopCookieValue('highestLevelOfEducation', value);
                fireFOS_DlEvent(name, value, formName, formType);
                prevHighestEduLevel = value;
                initialValues.highestLevelOfEducation = value
                //validateOneStep(initialValues);
            }
        }
    }
    
    handleAnalyticsRFIFormStart = () => {
        if(this.state.rfi_begin !== true){
            fireAnalyticsEvent('RFIStart', formName, formType);
            this.setState({ rfi_begin: true });
        }
    }    

    resetDegreeLevel(fosValue) {
        const newDegreeLevelOptions = getDegreeLevel(this.state.initialDegreeLevel,fosValue,formRef?.current?.values.isRegisteredNurse);// Ex: Bachelors, Individual Courses
        //check if degreeLevel was touched
        if ((formRef.current.values.degreeLevel !== '' && courseCategoryFlag === false)) {
          var optionExists = newDegreeLevelOptions.some((e)=>this.state.initialValues.degreeLevel===e.value);
          //if prev degree level doesnt exist, reset
          if (!optionExists) formRef.current.setFieldValue('degreeLevel', '')
        }
        this.setState({degreeLevel:newDegreeLevelOptions}); // EX: Bachelors, Individual Courses
    }

    handleZipcodeChange = (zipcode, state) => {
        if (zipcode !== null && zipcode !== undefined && zipcode !== "undefined" && zipcode !== '') {
            formRef.current.values.postalCode = zipcode;
            formRef.current.values.stateProvince = state;
            updatePrePopCookieValue('zipcode',zipcode);
            updatePrePopCookieValue('state', state);

            if (state && prevState !== state) {
                prevState = state;
            }
            this.updateAndNotify(state);
        }
    }

    updateAndNotify = (state) => {
        this.setState({ stateProvince: state });
     }

    handlePhoneNumberBlur = (value) => {
        formRef.current.values.phoneNumber = value;
    }    

    getCBEText() {
        let cbeText = "I'd like to learn about earning my master's degree in <strong>less than 1 year and under $11k.</strong>";
        return cbeText;
    }

    isCBEConsent = (event, checked) => {
        console.log(event); 
        console.log(checked)
        formRef.current.values.isCBE = checked;        
    }

    handleSubmit = (values) => {
        /*    if (((values.fieldOfStudy === 'technology' && values.degreeLevel !== "nondegree") || values.degreeLevel === 'masters'))  {
                values.flex_RFI_Callback__c = 'rfiCallback-control';
            } */
            if (document.activeElement.name === "submit") {
                this.setState({ initialValues: values });
                submitForm(values, this.props);
            }  /* else if(document.activeElement.name !== "submit"){
                event.preventDefault();
            } */
            
        }

    render() {
        const { classes } = this.props;
        const {
            activeStep,
            initialValues,
        } = this.state;
        return (
            <div>
                <UOPHeader />
                <main className="rfi">
                    <Grid container justifyContent='center' className="mobileFormContainer">
                    <Grid container className="maxWidthContainer-oneStepForm">
						<Grid item md={12} sm={12} lg={12} xs={12} >
						  <div className="newOneForm-Eyebrow">UNIVERSITY OF PHOENIX</div>     
                            {isSavingsExplorer?
                                <Grid item md={12} sm={12} lg={7} xs={12}><h1 className="newOneForm-title" role="heading" aria-level="1">Every dollar counts. Share your info so we can talk about saving options.</h1></Grid>:
                                <Grid item md={12} sm={12} lg={8} xs={12}><h1 className="newOneForm-title" role="heading" aria-level="1">We'd love to get to know you — and your goals</h1></Grid>
                            }      
						</Grid>
                        <Grid item md={12} sm={12} lg={7} xs={12} >
                            
                            <div className="newOneForm-SubTitle">{subHeader}</div>
                            <Typography
                                variant='body2'
                                component='div'
                                className="reqinfo"
                            >
                                * Required information
                            </Typography>
                            <Grid item md={12} sm={12} lg={12} xs={12}>
                                <Formik
                                    initialValues={initialValues}
                                    onSubmit={this.handleSubmit}
                                    validate={(values) => validateOneStep(values)}
                                    innerRef={form => formRef.current = form}
                                    validateOnChange>
                                    {(props) => {
                                        return (
                                            <Form className="mobile-form"
                                                  aria-label="Request For Information"
                                                  style={{ position: 'relative' }}
                                                  onChange={this.handleAnalyticsRFIFormStart}>
                                             <Grid container>
                                               <Grid item md={12} sm={12} lg={12} xs={12} className ="margin-OneStepForm">
                                               <SelectField
                                                    id="fieldOfStudy"
                                                    name="fieldOfStudy"
                                                    label="Area of study *"
                                                    required={true}
                                                    ariaRequired="true"
                                                    ariaLabel="select Area of study option"
                                                    data={this.state.fieldOfStudyOptions}
                                                    className="MuiFormControl-fullWidth"
                                                    formRef={formRef}
                                                    onClick={this.handleAnalyticsRFIFormStart}
                                                    onChange={this.dropdownChange("fieldOfStudy", props.values.fieldOfStudy)}
                                                ></SelectField>
                                                {roleChange()}
                                                 </Grid>
                                                 {conditionalRadio(props.values.fieldOfStudy) && <>
                                                    <Grid item md={12} sm={12} lg={6} xs={12} className ="margin-OneStepForm">
                                                        <MaterialRadio value={formRef?.current?.values.isRegisteredNurse} row={true} className="selectRadioLabel" onChange={this.handleChange} items={radioOption} label="Are you currently a registered nurse? *" name='isRegisteredNurse' ariaLabel="Are you currently a Registered Nurse?"></MaterialRadio>
                                                    </Grid>    
                                                    </>}                                                
                                                {props.values.fieldOfStudy !== "nondegree" && <>
                                                 <Grid item md={12} sm={12} lg={12} xs={12} className ="margin-OneStepForm">
                                                 <SelectField
                                                    id="degreeLevel"
                                                    name="degreeLevel"
                                                    label="Degree level *"
                                                    data={this.state.degreeLevel}
                                                    className="MuiFormControl-fullWidth"
                                                    ariaRequired="true"
                                                    ariaLabel="select Degree level option"
                                                    formRef={formRef}
                                                    onClick={this.handleAnalyticsRFIFormStart}
                                                    onChange={this.dropdownChange("degreeLevel", props.values.degreeLevel)}
                                                ></SelectField>
                                                {roleChange()}
                                                 </Grid>
                                                </>}
                                                {props.values.fieldOfStudy === "nondegree" && <>
                                                <Grid item md={12} sm={12} lg={12} xs={12} className ="margin-OneStepForm">
                                                    <SelectField
                                                        id="flex_Course_Category__c"
                                                        name="flex_Course_Category__c"
                                                        label="Course category *"
                                                        data={courseCategoryDropdown}
                                                        className="MuiFormControl-fullWidth"
                                                        formRef={formRef}
                                                        onClick={this.handleAnalyticsRFIFormStart}
                                                        onChange={this.dropdownChange("flex_Course_Category__c", props.values.flex_Course_Category__c)}
                                                    ></SelectField>
                                                    {roleChange()}
                                                </Grid>
                                                </>}
                                                 {conditionalCBE(props.values.fieldOfStudy, props.values.degreeLevel) && <>
                                                    <Grid item md={12} sm={12} lg={12} xs={12} className ="margin-OneStepForm cbe">
                                                        <SingleCheckbox
                                                                className="cbe-checkbox"
                                                                id="isCBE"
                                                                label={unescapeHTML(this.getCBEText())}
                                                                //ariaLabel={unescapeHTML(this.getCBEText())}
                                                                checkboxName="isCBE"
                                                                parentCallback={this.isCBEConsent}
                                                            />
                                                    </Grid>
                                                </>}
                                                <Grid item md={12} sm={12} lg={12} xs={12} className="margin-OneStepForm">
                                                    <SelectField
                                                        id="highestLevelOfEducation"
                                                        name="highestLevelOfEducation"
                                                        label="What is the highest level of education you achieved?"
                                                        data={higherEducation}
                                                        ariaRequired="false"
                                                        className="MuiFormControl-fullWidth"
                                                        formRef={formRef}
                                                        onChange={e => this.dropdownChange('highestLevelOfEducation', e.target.value)}
                                                    />
                                                </Grid>
                                                <Grid item md={12} sm={12} lg={6} xs={12} className ="margin-OneStepForm">
                                                <MaterialInput name="firstName" id="firstName"
                                                    label="First name *" ariaRequired="true" ariaLabel="Enter First name"  />
                                                </Grid>
                                                <Grid item md={12} sm={12} lg={6} xs={12} className ="margin-OneStepForm">
                                                <MaterialInput name="lastName" id="lastName"
                                                    label="Last name *" ariaRequired="true" ariaLabel="Enter Last name" />
                                                </Grid>
                                                <Grid item md={12} sm={12} lg={6} xs={12} className ="margin-OneStepForm">
                                                <MaterialInput name="emailAddress" id="emailAddress" label="Email address *" ariaRequired="true" ariaLabel="Enter Email address" variant="outlined" />
                                                </Grid>
                                                <Grid item md={12} sm={12} lg={6} xs={12} className ="margin-OneStepForm">
                                                <MaterialPhoneNumber name="phoneNumber" label="Phone number *" ariaRequired="true" ariaLabel="Enter phone number" handleBlur={this.handlePhoneNumberBlur}></MaterialPhoneNumber>
                                                </Grid>
												<Grid item md={12} sm={12} lg={6} xs={12} className ="margin-OneStepForm">
                                                <ZipCode value={formRef?.current?.values.postalCode} type="zipcode" id="zipcode" label="ZIP code *" ariaRequired="true" aria-describedby="Enter Zip code" aria-label="Enter Zip code" handleChange={this.handleZipcodeChange} formRef={formRef}></ZipCode>
												</Grid>
												<Grid item md={12} sm={12} lg={6} xs={12} className ="margin-OneStepForm">
                                                <MaterialRadio value={formRef?.current?.values.isMilitary} row={true} className= 'selectRadioLabel' onChange={this.handleChange} items={radioOption} label="Do you have any U.S. Military affiliation? *" name='isMilitary' ariaLabel="Do you have any U.S. Military affiliation?"></MaterialRadio>
												 </Grid>                                          
                                                 <Grid item md={12} sm={12} lg={12} xs={12} className ="margin-OneStepForm">
                                                   <div style={{fontSize:"14px", fontWeight:"normal", marginBottom:'18px'}}>You might be <strong>eligible for tuition savings</strong> if your employer has an alliance with us. Talk to your enrollment representative about it — as well as all the other ways you can <strong>save time and money</strong> on your degree.</div>
                                                </Grid>												
                                                 <Grid item md={12} sm={12} lg={6} xs={12} className ="margin-OneStepForm">
                                                     <div className="employer">
                                                        <MaterialInput name="flex_Employer_Disclosed_on_RFI__c" id="flex_Employer_Disclosed_on_RFI__c"
                                                            label="Employer name (optional)" ariaLabel="Employer name (optional)"  />
                                                    </div>
                                                 </Grid> 
                                                <div className="disclaimer">
                                                  {unescapeHTML(disclaimerText)}    
                                                </div>
                                                <Button name='submit' size='large' type='submit' className="btn-submit">
                                                            Request information
                                                        </Button>
                                                </Grid>
											<FormikErrorFocus offset={-50} align={'middle'} focusDelay={200} ease={'linear'} duration={1000} />
                                            </Form>
                                        )
                                    }}
                                </Formik>
                            </Grid>
                        </Grid>
                        <Grid item md={10} sm={10} lg={1} xs={12}></Grid>
                        <Grid item md={12} sm={12} lg={3} xs={12} className="maxWidthContainer-twoGridoneStepForm">
                        <h2 className="oneForm-four-grid-step" role="heading" aria-level="2"><span className="oneStepSolidBlock">Why</span> choose us</h2>

                        {/*        <div className="trust_score_wrapper" style={{ fontSize: "16px", lineHeight: '18px', color: "#001823", textDecoration: "none solid rgb(0, 24, 35)", fontFamily: "Roboto", maxHeight: "20px", display: "none", marginBottom: "3.5rem", marginTop: "2.5rem" }}>
                                    <span className="score_block" style={{ color: "#001823", paddingRight: "9px" }}><a href="/students/reviews.html"
                                                                                                                       rel="noopener noreferrer"
                                                                                                                       target="_blank"><span className="trust_score" style={{ color: "#001823", fontWeight: "bold", paddingRight: "4px", marignLeft: "4px" }}>{feedback?.score?.trustScore}</span></a> out  of  5</span>
                                    <span className="star_rating" style={{ paddingLeft: "3px", paddingRight: "2px" }}>
                                        {feedback?.score?.stars === 0 &&
                                            <img className="0_star_icon hidden star_img" src="https://www.phoenix.edu/content/dam/uopx/images/Trustpilot_ratings_0star_grey-RGB-128x24.png" alt="" style={{ display: 'inline-block', height: "16px" }}></img>}
                                        {feedback?.score?.stars === 1 &&
                                            <img className="1_star_icon hidden star_img" src="https://www.phoenix.edu/content/dam/uopx/images/Trustpilot_ratings_1star-RGB-128x24.png" alt="" style={{ display: 'inline-block', height: "16px" }}></img>}
                                        {feedback?.score?.stars === 1.5 &&
                                            <img className="1.5_star_icon hidden star_img" src="https://www.phoenix.edu/content/dam/uopx/images/Trustpilot_ratings_1halfstar-RGB-128x24.png" alt="" style={{ display: 'inline-block', height: "16px" }}></img>}
                                        {feedback?.score?.stars === 2 &&
                                            <img className="2_star_icon hidden star_img" src="https://www.phoenix.edu/content/dam/uopx/images/Trustpilot_ratings_2star-RGB-128x24.png" alt="" style={{ display: 'inline-block', height: "16px" }}></img>}
                                        {feedback?.score?.stars === 2.5 &&
                                            <img className="2.5_star_icon hidden star_img" src="https://www.phoenix.edu/content/dam/uopx/images/Trustpilot_ratings_2halfstar-RGB-128x24.png" alt="" style={{ display: 'inline-block', height: "16px" }}></img>}
                                        {feedback?.score?.stars === 3 &&
                                            <img className="3_star_icon hidden star_img" src="https://www.phoenix.edu/content/dam/uopx/images/Trustpilot_ratings_3star-RGB-128x24.png" alt="" style={{ display: 'inline-block', height: "16px" }}></img>}
                                        {feedback?.score?.stars === 3.5 &&
                                            <img className="3.5_star_icon hidden star_img" src="https://www.phoenix.edu/content/dam/uopx/images/Trustpilot_ratings_3halfstar-RGB-128x24.png" alt="" style={{ display: 'inline-block', height: "16px" }}></img>}
                                        {feedback?.score?.stars === 4 &&
                                            <img className="4_star_icon hidden star_img" src="https://www.phoenix.edu/content/dam/uopx/images/Trustpilot_ratings_4star-RGB-128x24.png" alt="" style={{ display: 'inline-block', height: "16px" }}></img>}
                                        {feedback?.score?.stars === 4.5 &&
                                            <img className="4.5_star_icon star_img" src="https://www.phoenix.edu/content/dam/uopx/images/Trustpilot_ratings_4halfstar-RGB-128x24.png" alt="" style={{ display: 'inline-block', height: "16px" }}></img>}
                                        {feedback?.score?.stars === 5 &&
                                            <img className="5_star_icon hidden star_img" src="https://www.phoenix.edu/content/dam/uopx/images/Trustpilot_ratings_5star-RGB-128x24.png" alt="" style={{ display: 'inline-block', height: "16px" }}></img>}
                                    </span>
                                    <span className="reviews_block" style={{ paddingLeft: "10px" }}>
                                        <a href="/students/reviews.html"
                                           rel="noopener noreferrer"
                                           target="_blank">
                                            <span className="reviews" style={{ color: "#001823", fontWeight: "bold" }}>{feedback?.numberOfReviews?.total} reviews
                                            </span>
                                        </a>
                                        from
                                    </span>
                                    <a style={{ height: "20px" }}
                                       rel="noopener noreferrer"
                                       href="https://www.trustpilot.com/review/phoenix.edu"
                                       target="_blank"><img style={{ height: "21px" }} className="trust_store_icon" src="/content/dam/uopx/images/Trustpilot_brandmark_gr-blk_RGB-144x36-M.png.png" alt="Trustpilot home"></img></a>
                                </div>
                        */}
                        <div className="oneForm-Rectangleline">
                            <ul >
                                <li className="oneStepForm-li" ><strong>No application fee, SAT, GMAT or essay requirements.</strong> We'll request your prior college transcripts for you, at no cost, when possible.</li>
                                <li className="oneStepForm-li">If you are a qualifying new student, you’ll be <strong>awarded one of our scholarships or the best savings</strong>  you qualify for.</li>
                                <li className="oneStepForm-li">Earn your degree faster, and for less. Eligible transfer credits could cover up to <strong>70% of your bachelor's degree</strong>.</li>
                                <li className="oneStepForm-li">Interactions with our academic counselors earned a <strong>5-star rating from 85% of our surveyed students*</strong>.</li>
                                
                            {/*    <li className="oneStepForm-li">More than <strong>100 online programs</strong> aligned to 300+ occupations.</li> */}                                
                            </ul>
                            <ul style={{listStyleType: "none"}}>
                                <li>
                                    <strong>*</strong> Transactional Survey; September 2022 – August 2023 (23,739 respondents)
                                </li>
                            </ul>
                            <img src={skillsblade} alt="Earn career-relevant skills in weeks - not years" style={{ width: "100%", marginTop:"24px" }}></img>
						</div>
                        </Grid>
                          </Grid>                    
                    </Grid>
                </main>
               <Footer />
            </div>
        );
    }
}

DynamicDesktopRFI.defaultProps = {
    classes: {},
};

DynamicDesktopRFI.propTypes = {
    classes: PropTypes.instanceOf(Object),
};

export default withStyles(styles)(DynamicDesktopRFI);